import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { theme as defaultTheme } from '@theme';

// import {
//   defaultTheme as defaultThemeStyle
// } from '@styles/theme';
import { createContext, ReactNode, useState } from 'react';

//ThemeName
export enum ThemeNames {
  default = 'default',
  backgroundGrey = 'backgroundGrey',
  backgroundGreyNoScroll = 'backgroundGreyNoScroll',
  backgroundPurple = 'backgroundPurple',
  backgroundWaterBlue = 'backgroundWaterBlue',
  backgroundGreen = 'backgroundGreen',
  backgroundOrange = 'backgroundOrange',
  backgroundFini = 'backgroundFini',
  backgroundWatermelon = 'backgroundWatermelon',
  backgroundWhite = 'backgroundWhite',
  backgroundSummit = 'backgroundSummit',
  backgroundHut ='backgroundHut'
}

interface ThemeContextInterface {
  setTheme(themeName: ThemeNames): any;
}

interface ThemeInterfaceProps {
  children: ReactNode | any;
  theme?: any;
}

export const ThemeContext = createContext<ThemeContextInterface>(
  {} as ThemeContextInterface
);

export default function CustomThemeProvider({
  children,
  theme,
}: ThemeInterfaceProps) {
  const [themeState, setThemeState] = useState(theme || defaultTheme);

  function setTheme(themeName: ThemeNames) {
    switch (themeName) {
      case ThemeNames.backgroundGrey:
        setThemeState(
          extendTheme(defaultTheme, {
            styles: {
              global: () => ({
                body: {
                  bg: '#C2C3C9',
                  color: '#000',
                },
              }),
            },
          })
        );
        break;
      case ThemeNames.backgroundGreyNoScroll:
        setThemeState(
          extendTheme(defaultTheme, {
            styles: {
              global: () => ({
                body: {
                  bg: '#FFFFFF',
                  color: '#000',
                  overflow: 'hidden',
                },
              }),
            },
          })
        );
        break;
      case ThemeNames.backgroundPurple:
        setThemeState(
          extendTheme(defaultTheme, {
            styles: {
              global: () => ({
                body: {
                  bgColor: '#8E26CD',
                  color: '#fff',
                },
              }),
            },
          })
        );
        break;
      case ThemeNames.backgroundGreen:
        setThemeState(
          extendTheme(defaultTheme, {
            styles: {
              global: () => ({
                body: {
                  // bgColor:"#00A542",
                  bgGradient:
                    'linear-gradient(180deg, #00A542 36.78%, #00BA22 51.68%)',
                  color: '#fff',
                },
              }),
            },
          })
        );
        break;
      case ThemeNames.backgroundOrange:
        setThemeState(
          extendTheme(defaultTheme, {
            styles: {
              global: () => ({
                body: {
                  // bgColor:"#00A542",
                  bgGradient:
                    'linear-gradient(180deg, #FF951A 36.78%, #FF951A 51.68%)',
                  color: '#fff',
                },
              }),
            },
          })
        );
        break;
      case ThemeNames.backgroundWaterBlue:
        setThemeState(
          extendTheme(defaultTheme, {
            styles: {
              global: () => ({
                body: {
                  // bgColor:"#D84654",
                  bgGradient:
                    'linear-gradient(180deg, #4B5BCA 36.78%, #4B5BCA 51.68%)',
                  color: '#fff',
                },
              }),
            },
          })
        );
        break;
      case ThemeNames.backgroundFini:
        setThemeState(
          extendTheme(defaultTheme, {
            styles: {
              global: () => ({
                body: {
                  // bgColor:"#D84654",
                  bgGradient:
                    'linear-gradient(180deg, #CA4B71 36.78%, #CA4B71 51.68%)',
                  color: '#fff',
                },
              }),
            },
          })
        );
        case ThemeNames.backgroundHut:
          setThemeState(
            extendTheme(defaultTheme, {
              styles: {
                global: () => ({
                  body: {
                    // bgColor:"#B1000B",
                    bgGradient:
                      'linear-gradient(180deg, #B1000B 36.78%, #B1000B 51.68%)',
                    color: '#fff',
                  },
                }),
              },
            })
          );
        
      case ThemeNames.backgroundWatermelon:
        setThemeState(
          extendTheme(defaultTheme, {
            styles: {
              global: () => ({
                body: {
                  // bgColor:"#D84654",
                  bgGradient:
                    'linear-gradient(180deg, #D84654 36.78%, #FF6776 51.68%)',
                  color: '#fff',
                },
              }),
            },
          })
        );
        break;
      case ThemeNames.backgroundWhite:
        setThemeState(
          extendTheme(defaultTheme, {
            styles: {
              global: () => ({
                body: {
                  bgColor: '#fff',
                  color: '#000',
                },
              }),
            },
          })
        );
        break;
      case ThemeNames.backgroundSummit:
        setThemeState(
          extendTheme(defaultTheme, {
            styles: {
              global: () => ({
                body: {
                  // bgColor:"#00A542",
                  bgGradient:
                    'linear-gradient(0deg, rgba(66,50,168,1) 0%, rgba(130,113,245,1) 100%)',
                  color: '#fff',
                },
              }),
            },
          })
        );
        break;
      default:
        setThemeState(defaultTheme);
        break;
    }
  }
  return (
    <ThemeContext.Provider value={{ setTheme }}>
      <ChakraProvider theme={themeState}>{children}</ChakraProvider>
    </ThemeContext.Provider>
  );
}
